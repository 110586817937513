<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <router-link to="/reports/custom" class="btn btn-w-shadow btn-sm btn-back" style="margin-right: 20px;">
						<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
					</router-link>
                    <h1 class="heading-page-h1">{{report.name}}</h1>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table" v-if="report.tableColumns&&report.tableData">
                    <thead>
                        <tr>
                            <th v-for="(columnName,index) in report.tableColumns" :key="index" >
                                <div class="table-filter">
                                    <span>{{columnName}}</span>
                                </div>
                            </th>
                            
                           
                        </tr>
                    </thead>
                    <tbody v-if="report.tableData.length>0">
                        <tr class="" v-for="(row,rowIndex) in report.tableData" :key="rowIndex">
                            <td v-for="(cellData,columnIndex) in report.tableData[rowIndex]" :key="columnIndex" >
                                {{ cellData }}
                            </td>
                      
                        </tr>
                    </tbody>
                </table>
                <TableMessages
                    :rows="report.tableData"
                    :filters="{}"
                    title="No Report Data"               
                    searchTitle="No Reports Corresponding To The Criteria"
                ></TableMessages>
               
            </div>

            <div class="items-group cards mt-15">
                <div class="cards__col-6">     
                    <h1>{{ $translate('images') }}</h1>
                    <div v-if="report.images&&report.images.length==0" style="margin-top:25px">{{ $translate('no-images') }}</div>

                    <div class="gallery" v-else>
                        <div class="gallery__item" v-for="image in report.images" :key="image.url">
                            <img :src="image.url" alt="" />
                        </div>
                    </div>
                </div>
                <div class="cards__col-6">
                    <h1>{{ $translate('comments') }}</h1>
                    <div v-if="report.comments&&report.comments.length==0" style="margin-top:25px">{{ $translate('no-comments') }}</div>

                    <p v-else class="comment" v-for="(comment,index) in report.comments" :key="index">
                        {{comment}}
                    </p>
                </div>
            </div>
        </main>

    </div>
</template>

<script>
import { getCustomReport } from "@/graphql/queries";
import { API,Storage } from "aws-amplify";
import { createProductsCategory, deleteCategory } from "@/graphql/mutations";

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import SimplePagination from "./SimplePagination";
import { mixDeleteItem } from "@/mixins";

export default {
    props:['id'],
    components: {
        ...Popups,
        ...Base,
        ...Buttons,
        SimplePagination
    },
    // mixins: [mixTables],
    data() {
        return {
            report:{},
            
        };
    },
    async created() {
        // await this.$store.dispatch(`GET_CUSTOMREPORTS`);
        // getCustomReport
        const report = await API.graphql({
            query: getCustomReport,
            variables: {
                id: this.id,
            },
        }).then((res) => res.data.getCustomReport);
        let imagesRequests=report.images.map(async key=>{
            return {url:await Storage.get(key)}
        })
        await Promise.all(imagesRequests).then(res=>report.images=res)
        this.report = report
    },
    computed: {
          
    },
    methods: {},
};
</script>

<style scoped>
.cards__col-6 {
    padding-left: 25px;
}
.comment{
    padding-top: 25px;
}
</style>
